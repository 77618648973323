@import 'fonts';
@import 'material_icons';
@import 'theming';

* {
  -webkit-font-smoothing: antialiased;
}

html,
body {
  margin: 0;
  height: 100%;
}

.full-width {
  width: 100%;
}

.mat-table {
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
    outline: none;
  }
}

.spacer {
  flex: 1 1 auto;
}

.mat-toolbar search-box {
  padding: 0 14px;
}

.nav-bar-container {
  display: flex;
  box-sizing: border-box;
  padding-right: 16px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  .mat-tab-link {
    text-transform: uppercase;
  }

  .nav-bar-buttons-container {
    display: flex;
    align-items: center;
  }
}

.no-padding .mat-dialog-container {
  padding: 0;
}

.close-btn .mat-dialog-container {
  position: relative;
  padding: 0;
}

.mat-paginator-container {
  background-color: #f0f0f0;
}

.mat-snack-bar-container {
  word-break: break-word;
  .mat-button {
    text-transform: uppercase;
  }
}

form .mat-card-content {
  font-size: 15px;
}

app-information-box.error {
  &:first-of-type {
    .mat-card {
      margin-top: 0;
    }
  }

  .mat-card {
    margin-top: 10px;
    background: map_get($mat-red, 500);
  }

  .mat-card-content {
    height: 40px;
  }
}

.mock-submit-button {
  display: none;
}

.customerDisableMsg {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;

  div {
    margin: auto 16px;
  }

  p {
    color: $dark-secondary-text;
  }

  .mat-icon {
    color: map_get($mat-red, 500);
    $icon-size: 104px;
    line-height: $icon-size;
    font-size: $icon-size;
    height: $icon-size;
    width: $icon-size;
  }
}

.info-list-tooltip {
  max-width: 288px !important;
  white-space: pre-line;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 16px;
}

// https://github.com/angular/components/issues/11470
.mat-header-cell,
.mat-cell,
.fixIE11verticallyCentered {
  min-height: auto;
}
.row-disabled .mat-cell {
  color: $dark-secondary-text;
}

// TODO: Replace priority-info-tooltip usages with checkbox-info-tooltip
// TODO: Replace messaging-patient-info-tooltip usages with checkbox-info-tooltip
.priority-info-tooltip,
.messaging-patient-info-tooltip,
.checkbox-info-tooltip {
  max-width: 230px !important;
  font-size: 12px;
  line-height: 16px;
  padding: 16px !important;
  white-space: pre-line;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 18px !important;
  padding: 10px 8px !important;
  font-size: 14px;
  font-weight: 500;
  color: rgba(38, 50, 56, 0.5);
}

.mat-button-toggle-checked {
  .mat-button-toggle-button {
    .mat-button-toggle-label-content {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.mat-sidenav-content {
  background: #f0f0f0;
  #router-wrapper {
    padding-bottom: 64px;
  }
}

.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
}

.mat-tab-group,
.mat-tab-nav-bar {
  &.mat-primary {
    > .mat-tab-header,
    > .mat-tab-link-container {
      .mat-ink-bar {
        background-color: #f7931e;
      }
    }
  }
}

.mat-tab-link.mat-tab-label-active {
  opacity: 1;
}

.mat-tooltip {
  color: #005594 !important;
  background-color: whitesmoke;
  font-weight: 500;
}
